<template>
    <div id="horloge-container" v-show="typehorloge?.type">
        <div id="horloge_grande" 
        v-show="typehorloge.taille === 'grande' && this.$route.path == '/cours/edition'">
            <article class="message is-primary">
                <div class="message-header " @click.prevent="minimiser">
                    <p>{{ typehorloge.type }}</p>
                    <button class="delete" aria-label="delete" @click.prevent="this.updateTypehorloge({type:''})"></button>
                </div>
                <div class="message-body" @click.prevent="pause" @wheel.prevent="changer_temps">
                    <div class="clock" style="margin-top: 30px auto; width: max-content;"></div>
                </div>
            </article>
        </div>
        <div data-v-step='horloge' id="horloge_miniature" :class="{peutMaximiser: (this.$route.path == '/cours/edition')}" 
        v-show="typehorloge.taille === 'miniature' || (this.$route.path != '/cours/edition')"
        @click.prevent="maximiser">
            <b-tag type="is-primary" size="is-medium" icon-pack="fas" :icon="icone" @close.prevent="this.updateTypehorloge({type:''})"  closable attached>{{ chiffres_heure }}</b-tag>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
export default {
    name: 'horloge',
    data() {
        return {
            clock: null,
            chiffres_heure: "hh:mm",
            timer: null,
            icone:"clock",
        };
    },
    mounted() {
        if (this.typehorloge.type == 'Chronomètre')
            this.chrono();
        else if (this.typehorloge.type == 'Minuteur')
            this.minuteur();
        else if (this.typehorloge.type == 'Horloge')
            this.heure();
        this.affiche_temps_minimiature(this.typehorloge)
        if (this.timer)
            clearInterval(this.timer)
        if (this.typehorloge.type !== '')
            this.timer = setInterval(this.affiche_temps_minimiature, 1000);
    },
    computed: {
        ...mapState(['typehorloge'])
    },
    watch: {
        typehorloge(newVal, oldVal) {
            if (newVal.type !== oldVal?.type)
            {
                if (newVal.type == 'Chronomètre')
                    this.chrono();
                else if (newVal.type == 'Minuteur')
                    this.minuteur();
                else if (newVal.type == 'Horloge')
                    this.heure();
            }
            this.affiche_temps_minimiature(newVal)
            if (this.timer)
                clearInterval(this.timer)
            if (newVal.type !== '')
                this.timer = setInterval(this.affiche_temps_minimiature, 1000);
        },
    },
    methods: {
        ...mapMutations(['updateTypehorloge']),
        maximiser() {
            this.updateTypehorloge({type:this.typehorloge.type, taille:'grande'})
        },
        minimiser() {
            this.updateTypehorloge({type:this.typehorloge.type, taille:'miniature'})
        },
        affiche_temps_minimiature(typehorloge) {
            if (typehorloge === undefined)
                typehorloge = this.typehorloge
            if (typehorloge.type == 'Horloge')
            {
                let date = new Date();
                this.chiffres_heure = date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
            }
            else if (typehorloge.type == 'Chronomètre')
            {
                this.chiffres_heure = this.formatTemps(this.clock.time.time -1);
            }
            else if (typehorloge.type == 'Minuteur')
            {
                this.chiffres_heure = this.formatTemps(this.clock.time.time +1);
            }
        },
        chrono() {
            this.icone="stopwatch-20";
            this.clock = $('.clock').FlipClock({
            clockFace: 'MinuteCounter',
            countdown: false,
			autoStart: false,
			language: 'fr-ca'
			});
            this.clock.setTime(0);
        },
        minuteur() {
            this.icone="stopwatch";
            this.clock = $('.clock').FlipClock({
            clockFace: 'MinuteCounter',
			autoStart: false,
            countdown: true,
			language: 'fr-ca'
			});
            this.clock.setTime(300);
        },
        heure() {
            this.icone="clock";
            this.clock = $('.clock').FlipClock({
            clockFace: 'TwentyFourHourClock',
            showSeconds: false,
			autoStart: true,
			language: 'fr-ca'
			});
        },
        pause() {
            if (this.typehorloge.type === "Horloge")
                return
            if (this.clock.running)
				this.clock.stop();
			else
				this.clock.start();
        },
        changer_temps(event) {
            if (this.typehorloge.type == "Minuteur")
            {
                var marche = this.clock.running;
                if (event.deltaY < 0) {
                    this.clock.stop();
                    this.clock.setTime(Number(this.clock.time) + 60 + (Number(this.clock.time) & 1) );
                    if (marche)
                        this.clock.start();
                }
                else {
                    this.clock.stop();
                    this.clock.setTime(Number(this.clock.time) <60 ? 0 : Number(this.clock.time) - 59);
                    if (marche)
                        this.clock.start();
                }
            }
            else if (this.typehorloge.type == "Chronomètre")
            {
                var marche = this.clock.running;
                if (event.deltaY < 0) {
                    this.clock.stop();
                    this.clock.setTime(Number(this.clock.time) + 60 - (Number(this.clock.time) & 1) );
                    if (marche)
                        this.clock.start();
                }
                else {
                    this.clock.stop();
                    this.clock.setTime(Number(this.clock.time) <60 ? 0 : Number(this.clock.time) - 61);
                    if (marche)
                        this.clock.start();
                }
            }
        },
        beforeDestroy() {
            // Clear the interval when the component is destroyed
            clearInterval(this.timer);
        },
        formatTemps(secondes) {
            // Vérifier si le paramètre est un nombre
            if (typeof secondes !== 'number' || isNaN(secondes)) {
                return "";
            }
            var minutes = Math.floor(secondes / 60);
            var secondesRestantes = secondes % 60;
            var minutesFormat = (minutes < 10) ? "0" + minutes : minutes;
            var secondesFormat = (secondesRestantes < 10) ? "0" + secondesRestantes : secondesRestantes;
            return minutesFormat + ":" + secondesFormat;
            }
    },
}
</script>

<style>
#horloge-container {
    opacity: 0.8;
}

#horloge_grande div.message-header,
#horloge_miniature.peutMaximiser span.tag.is-primary {
    cursor: pointer;
}

#horloge_miniature span.tag.is-primary {
    cursor: default;
}
#horloge-container {
    z-index: 2;
    position: fixed;
    bottom: 0;
    right: 0;
}
</style>