import configurationParDefaut from "../assets/configDemo.json";
import PouchDB from 'pouchdb-browser'
import Dropbox from '@/js/Dropbox';
import SauvegardeCours from "./SauvegardeCours";

import deepDiff, { diff } from "deep-diff";


// Pour le Serveur, tout se passe comme du local, mais le démon s'occuppe de la synchro comme un grand
class FdcConfig {
  TEMPORAIRE = {
    type: "temporaire",
    message:"La configuration est effacée à la fermeture du navigateur",
    sync: (configuration_demandée, sens) => this.syncTemporaire  (configuration_demandée, sens),
    desactiver: () => this.NeRienFaire(),
    RécupèreLeCours: (id) => this.NeRienFaire(),
    SupprimeLeCours: (id) => this.NeRienFaire(),
    Nuage: false,
  };
  NAVIGATEUR = {
    type: "navigateur",
    message:"La configuration est enregistrée dans le navigateur",
    sync: (configuration_demandée,  sens) => this.syncPartagé  (configuration_demandée, sens),
    get: () => this.getNavigateur(),
    set: (configuration) => this.setNavigateur(configuration),
    desactiver: () => this.effaceDonnéesNavigateur(),
    delais_maj: 0,
    RécupèreLeCours: (cours) => this.RécupèreLeCoursNavigateur(cours),
    SupprimeLeCours: (cours) => this.SupprimeLeCoursNavigateur(cours),
    Nuage: false,
  };
  DROPBOX = {
    type: "dropbox",
    message:"La configuration est en ligne avec Dropbox",
    sync: (configuration_demandée, sens) => this.syncPartagé  (configuration_demandée, sens),
    get: () => this.getDropbox(),
    set: (configuration) => this.setDropbox(configuration),
    desactiver: () => this.effaceDonnéesDropBox(),
    delais_maj: 30000,
    RécupèreLeCours: (cours) => this.RécupèreLeCoursDropbox(cours),
    SupprimeLeCours: (cours) => this.SupprimeLeCoursDropbox(cours),
    Nuage: true,
  };

  fdc_configuration = null;
  timestamp_next_maj = 0;
  configuration_en_cours = null;
  $buefy = null;

  constructor($buefy) {
    this.$buefy = $buefy
    this.fdc_configuration = this.TrouveConfiguration();
  }

  getTypeStockage() {
    return {
      type: this.fdc_configuration.type,
      message: this.fdc_configuration.message,
      nuage: this.fdc_configuration.Nuage
    }
  }

  besoin_mise_a_jour() {
    if (this.timestamp_next_maj < Date.now())
    {
      this.timestamp_next_maj = Date.now() + this.fdc_configuration.delais_maj;
      return true;
    }
    return false;
  }
  get() {
    return this.fdc_configuration.get()
  }
  set(configuration) {
    return this.fdc_configuration.set(configuration)
  }
  RécupèreLeCours(id) {
    return this.fdc_configuration.RécupèreLeCours(id);
  }
  SupprimeLeCours(id) {
    return this.fdc_configuration.SupprimeLeCours(id);
  }

  appliqueToutesLesDifferences(differences, destination)
  {
    try {
      if (differences)
      {
        differences.forEach(difference => {
          deepDiff.applyChange(destination, null, difference);
        });
      }
    }
    catch (e) {
      throw new Error(`appliqueToutesLesDifferences - Une erreur est survenue : ${e}`)
    }
  }

  /**
   * configuration_demandée : ce que je veux écrire dars la configuration | null si je n'ai pas encore de configuration
   * this.configuration_en_cours : ce que je dois modifier sans écraser l'objet
   * sens: "EcraseLeStore" pour écraser le store, "Sync" pour les deux sens, "EcrasePartagé" pour écraser config enregistrée dropbox/navigateur
   * Retourne
   * {
   *  false | message
   * }
   **/
  sync(configuration_demandée, sens) {
    return this.fdc_configuration.sync(configuration_demandée, sens);
  }

  desactiver() {
    this.fdc_configuration.desactiver();
  }

  effaceDonnéesNavigateur() {
    localStorage.removeItem('configuration');
    localStorage.removeItem('typeStockage');
  }
  effaceDonnéesDropBox() {
    let dbx = new Dropbox();
    dbx.revoke()
    this.effaceDonnéesNavigateur
  }

  syncTemporaire(configuration_demandée, sens) {
    switch (sens) {
      case "EcraseLeStore":
        if (configuration_demandée != null)
            {
              this.configuration_en_cours = configuration_demandée
              return Promise.resolve(configuration_demandée);
            }
        else
            {
              this.configuration_en_cours = configurationParDefaut
              return Promise.resolve(configurationParDefaut);
            }
      
      default:
        this.configuration_en_cours = configuration_demandée
        return Promise.resolve(configuration_demandée)
      }
  }

  // Promesse : resolve : configuration après sync | reject → texte erreur
  syncPartagé(configuration_demandée, sens) {
    switch (sens) {
      case "EcraseLeStore":
        return new Promise( (resolve, reject) => {
          if (configuration_demandée == null)
            // Si la configuration demandée est null, c'est que l'appli n'a pas encore de configuration : on la récupère
            {
              this.get().then( config => resolve(config) ).catch(err => reject(err))
            }
          else
            // Si la configuration demandée est non null, c'est que l'appli a une configuration et que l'on a importé une nouvelle  on retourne configuration_demandée
            {
              resolve(configuration_demandée)
            }
        })
        .then( (configuration_en_cours) => {
            this.configuration_en_cours = _.cloneDeep(configuration_en_cours)
            this.set(configuration_en_cours)
            return Promise.resolve(configuration_en_cours)
        })
      

      case "EcrasePartagé":
        this.configuration_en_cours = _.cloneDeep(configuration_demandée)
        return this.set(configuration_demandée)
      
      case "Sync":
        // Si je veux juste charger la config (donc la demandée et l'actuelle sont les mêmes) et que la dernière a moins de X s, on retourne la dernière
        if (!this.besoin_mise_a_jour() && _.isEqual(configuration_demandée,this.configuration_en_cours))
        {
          return Promise.resolve(this.configuration_en_cours)
        }
        return this.get()
        .then( (configuration_en_ligne) => {
          try {
            // On trouve ce qui a changé par rapport à ce que l'on avait en mémoire et on l'applique sur la configuration en ligne.
            let configuration_finale = _.cloneDeep(configuration_en_ligne)
            let differences_demandées = deepDiff(this.configuration_en_cours, configuration_demandée);
            this.appliqueToutesLesDifferences(differences_demandées, configuration_finale)

            // ↓ Cette partie se fait en asynchrone et se gère toute seule.
            // avant le return qui suivra
            this.set(configuration_finale)
                .then ( configuration_finale => {
                  // La configuration est mise à jour dans le store
                  // On met à jour le timestamp_next_maj
                  this.timestamp_next_maj = Date.now() + this.fdc_configuration.delais_maj;
                  this.configuration_en_cours = _.cloneDeep(configuration_finale);
                })
                .catch( erreur => {
                  parametres.$buefy.snackbar.open({
                    duration: 10000,
                    message: `Une erreur est survenue lors de la synchronisation de la configuration : ${erreur}`,
                    type: 'is-danger',
                    position: 'is-top',
                    actionText: 'ok',
                    queue: false
                  })
                })
            // ↑ fin asynchrone
            // Sera fait tout de suite, sans attendre la fin de set()
            return Promise.resolve(configuration_finale)
          }
          catch (err) {
            return Promise.reject("syncPartagé : " + err)
          }
        })
    }
  }

   getNavigateur() {
      try
      {
        let config = JSON.parse(localStorage.getItem("configuration"))
        return Promise.resolve(config)
      }
      catch (e)
      {
        return Promise.reject(e)
      }
    
  }

  getDropbox() {
    let dbx = new Dropbox()
    return dbx.getFileJson("/config.json")
  }

  setDropbox(configuration) {
    if (_.isEqual(configuration, this.configuration_en_cours)){
      return Promise.resolve(configuration)
    }
    let dbx = new Dropbox()
    return dbx.saveFileTexte("/config.json", JSON.stringify(configuration))
    .then( () => Promise.resolve(configuration))
  }

  setNavigateur(configuration) {
    
      try
      {
        let strconfiguration =  JSON.stringify(configuration);
        if (strconfiguration == undefined)
        {
          return Promise.reject("Une erreur est survenue lors de l'enregistrement de la configuration demandée [setNavigateur] JSON.stringify(configuration) est undef")
        }
        localStorage.setItem("configuration", strconfiguration);
        return Promise.resolve(configuration)
      }
      catch (e)
      {
        return Promise.reject("Une erreur dans setNavigateur" +e)
      }
  }

  // On ne fait rien de particulier
  NeRienFaire() {}

  /*
   * Active le type de stockage passé en argument
   * Si configuration n'est pas null, écrase celle qui existe déjà par celle passée en arguments
   * 
   */
  activer(typeStockage, configuration) {
    switch (typeStockage) {
      case "dropbox":
        localStorage.setItem('typeStockage', "dropbox")
        this.fdc_configuration = this.DROPBOX;
        if (configuration != null)
        {
          // On doit écraser le fichier sur le serveur avec notre configuration
          let dbx = new Dropbox()
          return dbx.saveFileTexte("/config.json", JSON.stringify(configuration))
        }
        return Promise.resolve()
      case "navigateur":
        this.fdc_configuration = this.NAVIGATEUR;
        localStorage.setItem('typeStockage', "navigateur")
        return Promise.resolve()
      case "temporaire":
        this.fdc_configuration = this.TEMPORAIRE;
        localStorage.clear()
        return Promise.resolve()
    }
  }

  RécupèreLeCoursNavigateur(cours) {
    let db = new PouchDB("cours_integraux")
    return db.get(cours._id)
  }

  SupprimeLeCoursNavigateur(cours) {
    let db_cours = new PouchDB("cours_integraux")
    let db_résumés = new PouchDB("resumes_des_cours")
    
    return Promise.all([db_cours.get(cours._id).then((doc) => db_cours.remove(doc)), db_résumés.get(cours._id).then( (doc) => db_résumés.remove(doc) )])
  }

  RécupèreLeCoursDropbox(cours) {
   
    let dbx = new Dropbox()
    return dbx.getFile(`/cours/${cours._id} — ${cours.titre}.html`)
    .then( (html) => {
      let sauvegarde = new SauvegardeCours({cours: cours, $buefy: this.$buefy, typeConfiguration: this.getTypeStockage().type})
      return sauvegarde.sauvegarde_navigateur_courscomplet_html(html)
    })
    .catch( () => {
      return this.RécupèreLeCoursNavigateur(cours)
    })
    
  }

  SupprimeLeCoursDropbox(cours) {
    let dbx = new Dropbox()
    // TODO modifier pour résumés.zip
    return dbx.deleteFiles([{ path: `/cours/${cours._id} — ${cours.titre}.html`}, { path: `/résumés/${cours._id} — ${cours.titre}.json`}])
    .then( () => {
      return this.SupprimeLeCoursNavigateur(cours)
    })
  }


  TrouveConfiguration() {
    switch (localStorage.getItem('typeStockage'))
    {
      case "navigateur":
        return this.NAVIGATEUR;
      case "dropbox":
        if (localStorage.getItem('dropbox_refresh_token'))
          return this.DROPBOX;
        else
          {
            this.$buefy.snackbar.open({
              duration: 10000,
              message: `Dropbox est sélectionné pour enregistrer la configuration, mais je n'ai pas les accès. On bascule sur une sauvegarde dans le navigateur.`,
              type: 'is-danger',
              position: 'is-top',
              actionText: 'ok',
              queue: false
          })
            this.activer("navigateur", null)
            .finally( () => {
              return this.NAVIGATEUR;
            })
          }
      default:
        return this.TEMPORAIRE;
    }
  }
}

export default FdcConfig;