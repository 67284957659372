<template>
  <div class="montre_un_cours">
    <b-modal
            v-model="afficher"
            has-modal-card
            trap-focus
            destroy-on-hide
            aria-role="dialog"
            aria-label="Example Modal"
            close-button-aria-label="Fermer"
            aria-modal>
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head is-flex is-justify-content-space-between" v-if="cours_complet?.titre">
               <div class="is-flex is-align-items-center">
                <b-taglist attached class="mr-1 mb-0">
                    <b-tag type="is-primary mb-0" v-if="cours_complet.classe">{{ cours_complet.classe }}</b-tag>
                    <b-tag type="is-success mb-0" v-if="!cours_complet.groupe && cours_complet.classe" icon="crowd" > Classe entière</b-tag>
                    <b-tag type="is-warning mb-0" v-if="cours_complet.groupe" icon="account-group">{{ cours_complet.groupe }}</b-tag>
                    
                    <b-tag type="is-primary mb-0" v-if="cours_complet.type_document === 'préparation'" icon="timer-sand"/>
                    <b-tag type="is-black mb-0" v-if="cours_complet.type_document === 'préparation'" >Préparation</b-tag>
                </b-taglist>
                <p v-if="cours_complet.type_document === 'cours'" class="modal-card-title">{{ format_date_complet(cours_complet?.date_creation) }}</p>
                <p v-if="cours_complet.type_document !== 'cours'" class="modal-card-title">{{ cours_complet?.titre }}</p>
              </div>
                <button
                    type="button"
                    class="delete"
                    @click="afficher = false"/>
            </header>
            <section class="modal-card-body">
              <article class="message is-danger" v-if="erreur">
                <div class="message-header">
                  <p>{{ erreur.titre}}</p>
                </div>
                <div class="message-body" v-html="erreur.message" />
              </article>
              <article class="message is-primary" v-if="cours_complet?.devoirs?.length">
                <div class="message-header">
                  <p>Devoirs</p>
                </div>
                <div class="message-body">
                  <article v-for="devoir in cours_complet.devoirs" class="message is-info">
                    <div class="message-header clipboard" @click="copie(devoir.contenu)">
                      <p>Pour le {{ format_date(devoir.date) }}</p>
                      <div>
                        <b-icon title="Copier au format texte" icon="clipboard-text"  />
                      </div>
                    </div>
                    <div class="message-body">
                          <div v-if="! télécharge" v-html="devoir.contenu"></div>
                          <div class="is-flex is-justify-content-center is-flex-direction-column" v-else>
                            <span>Télécharge…</span>
                            <b-icon pack="fas" icon="spinner" size="is-small" custom-class="fa-spin"/>
                          </div>
                    </div>
                  </article>
                </div>
              </article>
              <article class="message"  :class="{'is-primary':!erreur, 'is-warning':erreur}" v-if="cours_complet?.contenu">
                <div class="message-header clipboard" @click="copie('Contenu de la séance en pièce jointe')">
                  <p>{{erreur? "Informations en mémoire - ":""}}Contenu de la séance</p>
                  <div>
                    <b-icon title="Copier « Contenu de la séance en pièce jointe »" icon="clipboard-text"  />
                  </div>
                </div>
                <div id="editor" class="message-body" v-html="cours_complet?.contenu" />
              </article>
              <article class="message is-primary" v-if="cours_complet?.pieces_jointes?.length">
                <div class="message-header">
                  <p>Pièces jointes</p>
                </div>
                <div class="message-body">
                  <b-taglist>
                    <b-tag class="pjlink" v-for="pj in cours_complet.pieces_jointes"
                    type="is-success"
                    @click.prevent="TéléchargerPJ(pj)"
                    >{{ pj.nom }} ({{ pj.taille }})</b-tag>
                </b-taglist>
                </div>
              </article>
                
            </section>
            <footer class="modal-card-foot is-flex is-justify-content-center">
              <b-button
                    v-if="cours_précédent"
                    :title="cours_précédent?.titre || 'Pas de précédent'"
                    icon-left="skip-previous"
                    @click="$emit('montre_moi_un_cours', {cours:cours_précédent, typeliste:typeliste})"  />
              <b-button
                    label="Supprimer"
                    type="is-danger"
                    @click="Supprimer" />
              <b-button
                    label="Éditer comme nouveau"
                    type="is-primary"
                    @click="Nouveau"
                    v-if="! cours_complet?.autosave" />
              <b-button
                    v-if="cours_complet?.autosave || cours_complet?.type_document === 'préparation' || (cours_complet?.classe && Object.values(configuration.classes).filter(c => c.nom === cours_complet?.classe).length)"
                    :label="cours_complet?.autosave?'Reprendre l\'édition':'Modifier'"
                    type="is-primary"
                    @click="Modifier" />
              <b-button
                    label="Télécharger"
                    type="is-primary"
                    @click="Télécharger"
                    v-if="! cours_complet?.autosave" />
                    <b-button
                    v-if="cours_suivant"
                    :title="cours_suivant?.titre || 'Pas de suivant'"
                    icon-left="skip-next"
                    @click="$emit('montre_moi_un_cours', {cours:cours_suivant, typeliste:typeliste})" />
            </footer>
         </div>
    </b-modal>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { fr } from "date-fns/locale";
import { mapState, mapMutations, mapGetters } from 'vuex';
import b64toBlob from 'b64-to-blob';
import useClipboard from 'vue-clipboard3'
import SauvegardeCours from '@/js/SauvegardeCours';
import PouchDB from 'pouchdb-browser';

export default {
  props: ['cours','typeliste'],
  name: 'MontreMoiUnCours',
   data() {
     return {
      télécharge: true,
      cours_complet: null,
      afficher: false,
      erreur: null,
      cours_suivant: null,
      cours_précédent: null,
     };
   },
    watch: {
      cours(newval, oldval) {
            if (newval && ! newval?.autosave)
            {
              this.cours_complet = {}
              this.afficher = true
              this.erreur = null
              this.télécharge = true
              this.maconfig.RécupèreLeCours(newval).then( doc => {
               
                this.télécharge = false
                this.cours_complet = _.merge({}, newval, doc) 
              })
              
              .catch((erreur) => {
                this.télécharge = false
                this.erreur = {titre: `Une erreur est survenue`, message: `Contenu incomplet. ${erreur}.<br />Il n'y a que le texte sans mise en forme ni pièces jointes.`}
                this.cours_complet = _.cloneDeep(newval)
              })
              .finally( () => {
                  this.$emit('cours_affiché')
                  $("#editor img").on('click', (evt) => {
                    let tableau_photos = []
                    let index = 0
                    let mon_image = evt.target.src
                    $(this.cours_complet.contenu).find('img').each((i, img) => {
                        tableau_photos.push(img.src)
                        if (img.src === mon_image)
                            index = i
                    })
                    this.$emit('montre_moi_une_image',{photos:tableau_photos, index: index})
                  })
                  this.trouveSuivantPrécédent(newval)
              })
            }
            // Affiche un autosave
            else if (newval)
            {
              this.afficher = true
              this.erreur = null
              this.cours_complet = _.cloneDeep(newval)
              this.trouveSuivantPrécédent(newval)
            }
        }
    },
  mounted() {
  },
  beforeUnmount() {
  },
  computed: {
    ...mapState(['maconfig','éditeurs','configuration','auteur'])
  },
  components: {
  },
  methods: {
    ...mapMutations(['updateCommandeEditeur','updateEditerCommeNouveau','updateModifListeCours']),
    TéléchargerPJ(pj) {
      const blob = b64toBlob(pj.contenu, pj.mime)
      saveAs(blob, pj.nom)
    },
    Télécharger() {
      this.$emit('chargement', {icone: 'cloud-arrow-down', tourne: false, texte: 'Téléchargement des données', annulable: true})
      let sauvegarde = new SauvegardeCours({cours: this.cours_complet, $buefy: this.$buefy, typeConfiguration: "Télécharge"})
      sauvegarde.sauvegarde()
      .then( () => this.$emit('chargement', false) )
    },
    Modifier() {
      let classe = _.find(this.configuration.classes, classe => classe.nom === this.cours_complet.classe) || ""
      let groupe = ''
      if (classe && classe.groupes && this.cours_complet.groupe)
        groupe = _.find(classe.groupes, groupe => groupe.nom === this.cours_complet.groupe) || ""
      let paramètres_éditeur = {};
      paramètres_éditeur.classe_id = classe.id || ''
      paramètres_éditeur.devoirs_donnés = []
      paramètres_éditeur.rappels = []
      paramètres_éditeur.groupe_id = groupe.id || ''
      paramètres_éditeur.document_parent_id = null;
      paramètres_éditeur.cours = {};
      paramètres_éditeur.cours._id = this.cours_complet._id
      paramètres_éditeur.cours.revcours_complet = this.cours_complet.revcours_complet
      paramètres_éditeur.cours.rev_résumé = this.cours_complet.rev_résumé
      paramètres_éditeur.cours.classe = this.cours_complet.classe
      paramètres_éditeur.cours.groupe = this.cours_complet.groupe
      paramètres_éditeur.cours.établissement = this.cours_complet.établissement
      paramètres_éditeur.cours.auteur = this.auteur || "Inconnu";
      paramètres_éditeur.cours.date_creation =  this.cours_complet.date_creation
      paramètres_éditeur.cours.date_modification = (new Date()).toISOString();
      paramètres_éditeur.cours.niveau = this.cours_complet.niveau
      paramètres_éditeur.cours.année_scolaire = this.cours_complet.année_scolaire
      paramètres_éditeur.cours.type_document = this.cours_complet.type_document
      paramètres_éditeur.cours.titre = this.cours_complet.titre;
      paramètres_éditeur.cours.pieces_jointes = this.cours_complet.pieces_jointes
      paramètres_éditeur.cours.contenu = this.cours_complet.contenu
      paramètres_éditeur.cours.devoirs = this.cours_complet.devoirs
      paramètres_éditeur.devoirs_donnés = []
      paramètres_éditeur.rappels = []
      this.afficher = false
      this.$emit('chargement', true)
      this.updateCommandeEditeur({commande: "nouveau", éditeur: paramètres_éditeur})
      this.$router.push('/cours/edition');
    },
    Nouveau() {
      let paramètres_éditeur = {};
      paramètres_éditeur.document_parent_id = this.cours_complet._id;
      paramètres_éditeur.cours = {};
      paramètres_éditeur.cours.auteur = this.auteur || "Inconnu";
      paramètres_éditeur.cours.pieces_jointes = this.cours_complet.pieces_jointes
      paramètres_éditeur.cours.contenu = this.cours_complet.contenu
      paramètres_éditeur.cours.devoirs = this.cours_complet.devoirs
      paramètres_éditeur.devoirs_donnés = []
      paramètres_éditeur.rappels = []
      this.afficher = false
      this.updateEditerCommeNouveau(paramètres_éditeur)
      this.$router.push({ name: 'cours-editer-comme-nouveau'})
    },
    Supprimer() {
      this.afficher = false;
      this.$buefy.dialog.confirm({
                    title: 'Suppression du cours',
                    message: `${this.cours_complet.titre}<br/>Voulez-vous <b>supprimer</b> ce cours ?`,
                    confirmText: 'Supprimer',
                    cancelText: 'Annuler',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                      this.SupprimeLeCours(this.cours_complet)
                        .then(() => {
                        this.updateModifListeCours()
                        this.$buefy.snackbar.open({
                          duration: 5000,
                          message: 'Cours supprimé avec succès',
                          type: 'is-success',
                          position: 'is-top',
                          actionText: 'ok',
                          queue: false
                          });  
                        })
                        .catch(erreur => {
                          this.updateModifListeCours()
                          this.$buefy.snackbar.open({
                            duration: 5000,
                            message: 'Échec de la suppression : ' + erreur,
                            type: 'is-danger',
                            position: 'is-top',
                            actionText: 'ok',
                            queue: false
                            });
                          })
                    }
                })
    },
    SupprimeLeCours(cours_complet) {

      if (cours_complet?.autosave)
        {
          let db_autosave = new PouchDB("autosave")
          return db_autosave.get(cours_complet._id).then( doc => {return db_autosave.remove(doc)})
        }
      return this.maconfig.SupprimeLeCours(cours_complet)
    },
    format_date(date) {
        return format(date, "iii d LLL", { locale: fr })
    },
    format_date_complet(date) {
        return format(date, "iii d LLL yyyy 'à' H'h'mm", { locale: fr })
    },
    async copie(html) {
      const cheerio = require('cheerio');
      let texte = cheerio.load(html).text()
      const { toClipboard } = useClipboard()
      toClipboard(texte)
      .then( () => {
        this.$buefy.snackbar.open({
            duration: 3000,
            message: `<b>Copié dans le presse papier :</b><br /><i>${texte}</i>`,
            type: 'is-primary',
            position: 'is-top',
            actionText: 'ok',
            queue: false
            })
      })
    },
    trouveSuivantPrécédent(cours) {
      let filter = (doc) => {return true}
      switch(this.typeliste)
      {
        case 'classe':
          filter = (doc) => {return doc.classe === cours.classe}
        case 'tous':
          this.cours_précédent = null
          this.cours_suivant = null
          let trouvé = false
          let db_résumés = new PouchDB("resumes_des_cours")
          db_résumés.allDocs({include_docs: true, descending: true}).then(docs => {
            for (let row of docs.rows)
            {
              if (trouvé && filter(row.doc))
              {
                this.cours_précédent = row.doc
                break
              }
              else if(row.id == cours._id)
              {
                trouvé = true
              }
              else if (! trouvé && filter(row.doc))
              {
                this.cours_suivant = row.doc
              }
            }
          })
          break;
        case 'aucun':
        default:
          this.cours_précédent = null
          this.cours_suivant = null
      }
    }
  }
   
}

</script>

<style>
div#editor.message-body div.divIcon > i.fas,
div#editor.message-body div.divIcon > i.fa-solid,
div#editor.message-body div.divIcon > i.far,
div#editor.message-body div.divIcon > i.fa-regular,
div#editor.message-body div.divIcon > i.fab,
div#editor.message-body div.divIcon > i.fa-brands
{
    font-size: 50px;
    font-style: unset;
}

div#editor img,
.clipboard,
.pjlink {
  cursor: pointer;
}

div#editor figure.image figcaption {
    text-align: center;
    margin-bottom: 1.5rem;
}


/*div#editor.message-body div.Boite[devoirs="true"] {
  display: none;
}*/
@import "@/assets/css/cours.css";


div.modal-background {
  backdrop-filter: blur(5px);
  background: rgba(0,0,0,.5);
}
div.montre_un_cours button.modal-close {
  display: none;
}
</style>