<template>
  <router-view />
</template>

<script>

export default {
  name: 'HomePage',

};
</script>