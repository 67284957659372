<template>
  <v-app full-height>
      <v-main >
        <router-view v-slot="{ Component }">
            <keep-alive>
              <component  :is="Component" />
            </keep-alive>
        </router-view>
      </v-main>
      
      <v-bottom-navigation
        v-model="activeIndex"
        :absolute="false"
        :value="activeIndex"
        mode="shift"
        color="primary"
        :disabled="typeConfiguration?.nuage === false"
        >
        <v-btn  size="small" v-for="(item, index) in items" :key="index" @click="navigate(index)" >
          <v-icon>{{ item.icon }}</v-icon>
          <span>{{ item.text }}</span>
        </v-btn>
      </v-bottom-navigation>
  </v-app>
</template>


<script>
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  
  data() {
    return {
      items: [
        { icon: 'mdi-logout', text: 'Réglages', route: '/mobile/serveur', textLong: 'Forge de cours' },
        { icon: 'mdi-file-document-multiple', text: 'Cours', route: '/mobile/derniers-cours', textLong: 'Les derniers cours' },
        { icon: 'mdi-crowd', text: 'Classes', route: '/mobile/classes', textLong: 'Les classes' },
        { icon: 'mdi-calendar-check', text: 'Listes', route: '/mobile/listes/listes', textLong: 'Listes' },
        { icon: 'mdi-camera-plus', text: 'Photos', route: '/mobile/photos', textLong: 'Envoyer une photo' },
      ]
    }
  },
  mounted() {
    this.syncConfiguration({configuration: null,  sens:"Sync", $buefy: this.$buefy})
      document.title = "Forge de cours – application mobile"

  },
  computed: {
    ...mapState(['typeConfiguration']),
    activeIndex() {
      switch (this.$route.path) {
        case "/mobile/server":
          return 0
        case "/mobile/derniers-cours":
          return 1
        case "/mobile/classes":
          return 2
        case "/mobile/listes/taches":
        case "/mobile/listes/listes":
          return 3
        case "/mobile/photos":
          return 4
      }
    },
    
  },
  methods: {
    ...mapMutations(['updateAutoriseNavigation']),
    ...mapActions(['syncConfiguration']),
    navigate(index) {
      const route = this.items[index].route;
      this.updateAutoriseNavigation(true)
      this.$router.push(route);
    }
  }
}
</script>

<style>

</style>