<template>
  <div id="lightbox">
    <vue-easy-lightbox
      :visible="visible"
      :imgs="photos"
      :index="index"
      :loop="true"
      :zoomScale="0.25"
      :maxZoom="5"
      @hide="$emit('close')" />
    </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'
export default {
  props: ['photos','index','visible'],
  name: 'MontreMoiUneImage',
  components: {
    VueEasyLightbox,
  },
  methods: {
  }
   
}

</script>

<style>
div.vel-modal {
  backdrop-filter: blur(5px);
}
</style>