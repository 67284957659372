<template>
    <div class="imagemenu mx-3 my-2">
        <b-image
            :src="src"
            ratio="1by1"
        />
    </div>
</template>

<script>
export default {
    name: 'PhotoMenu',
    props: ["src"],
}
</script>

<style>
div.imagemenu {
    max-width: 300px;
}
div.imagemenu img {
    border-radius: 10px;
    cursor: pointer;
    max-width: 300px;
}
</style>