import { createRouter, createWebHistory } from 'vue-router'
import bureau from '../views/mises-en-page/bureau.vue'
import mobile from '../views/mises-en-page/mobile.vue'

const routes = [
  {
    path: '/mobile',
    component: mobile,
    children: [
        {
          path: 'chargement',
          name: 'mobile-chargement',
          component: function () { return import(/* webpackChunkName: "mobile-chargement" */ '../views/mobile/chargement.vue') }
        },
        {
          path: 'serveur',
          name: 'mobile-serveur',
          component: function () { return import(/* webpackChunkName: "mobile-serveur" */ '../views/mobile/serveur.vue') }      
        },
        {
          path: 'photos',
          name: 'mobile-photos',
          component: function () { return import(/* webpackChunkName: "mobile-photos" */ '../views/mobile/photos.vue') }
        },
        {
          path: 'listes/:type',
          name: 'mobile-listes',
          props: true,
          component: function () { return import(/* webpackChunkName: "mobile-listes" */ '../views/mobile/listes.vue') }      
        },
        {
          path: 'classes',
          name: 'mobile-classes',
          component: function () { return import(/* webpackChunkName: "mobile-classes" */ '../views/mobile/classes.vue') }      
        },
        {
          path: 'derniers-cours',
          name: 'mobile-derniers-cours',
          component: function () { return import(/* webpackChunkName: "mobile-derniers-cours" */ '../views/mobile/derniers-cours.vue') }      
        },
        {
          path: 'configuration/dropbox',
          name: 'mobile-configuration-dropbox',
          component: function () { return import(/* webpackChunkName: "mobile-configuration-dropbox" */ '../views/mobile/configuration-dropbox.vue') }      
        },
        // ROUTE 404
        {
          path: '/mobile/:pathMatch(.*)*',
          name: 'NotFound',
          component: function () { return import(/* webpackChunkName: "mobile-erreur-404" */ '../views/mobile/erreur-404.vue') }    
        }
    ],
  },
  {
    path: '/',
    component: bureau,
    children: [
      {
        path: '/',
        name: 'home',
        component: function () { return import(/* webpackChunkName: "HomePage" */ '../views/HomeView.vue') }
      },
// COURS
      {
        path: 'cours/choix-classe',
        name: 'cours-choix-classe',
        component: function () { return import(/* webpackChunkName: "cours-choix-classe" */ '../views/cours/choix-classe.vue') }
      },
      {
        path: 'cours/editer-comme-nouveau',
        name: 'cours-editer-comme-nouveau',
        component: function () { return import(/* webpackChunkName: "cours-editer-comme-nouveau" */ '../views/cours/choix-classe.vue') }
      },
      {
        path: 'cours/edition',
        name: 'cours-edition',
        beforeEnter: (to, from, next) => {
          if (from.name) {
            next()
          } else {
            next({ name: 'cours-choix-classe' })
          }
        },
        meta: { keepAlive: true } ,
        component: function () { return import(/* webpackChunkName: "cours-edition" */ '../views/cours/edition.vue') }      
      },
      {
        path: 'cours/liste-des-cours',
        name: 'cours-liste-cours',
        component: function () { return import(/* webpackChunkName: "cours-liste-cours" */ '../views/cours/liste-des-cours.vue') }      
      },
// CONFIGURATION
      {
        path: 'configuration/ou-sauvegarder/:onglet',
        name: 'configuration-ou-sauvegarder',
        props: true,
        component: function () { return import(/* webpackChunkName: "configuration-ou-sauvegarder" */ '../views/configuration/ou-sauvegarder.vue') }      
      },
      {
        path: 'configuration/dropbox',
        name: 'configuration-dropbox',
        component: function () { return import(/* webpackChunkName: "configuration-dropbox" */ '../views/configuration/dropbox.vue') }      
      },
      {
        path: 'configuration/liens',
        name: 'configuration-liens',
        component: function () { return import(/* webpackChunkName: "configuration-liens" */ '../views/configuration/liens.vue') }      
      },
      {
        path: 'configuration/classes',
        name: 'configuration-classes-liste',
        component: function () { return import(/* webpackChunkName: "configuration-classes-liste" */ '../views/configuration/classes-liste.vue') }      
      },
      {
        path: 'configuration/etablissements',
        name: 'configuration-etablissements',
        component: function () { return import(/* webpackChunkName: "configuration-classes-liste" */ '../views/configuration/etablissements.vue') }      
      },
      {
        path: 'configuration/classes/:classeid',
        name: 'configuration-classes-edition',
        props: true,
        component: function () { return import(/* webpackChunkName: "configuration-classes-edition" */ '../views/configuration/classes-edition.vue') }      
      },     
      {
        path: 'configuration/editeur',
        name: 'configuration-editeur',
        component: function () { return import(/* webpackChunkName: "configuration-editeur" */ '../views/configuration/editeur.vue') }      
      }, 
 // TIRAGE
      {
        path: 'tirage/',
        name: 'tirage-classes',
        props: true,
        component: function () { return import(/* webpackChunkName: "configuration-classes-edition" */ '../views/tirage/tirage.vue') }      
      },
// INFOS LEGALES
      {
        path: 'version',
        name: 'version',
        component: function () { return import(/* webpackChunkName: "CGU" */ '../views/legal/version.vue') }      
      },
      {
        path: 'CGU',
        name: 'CGU',
        component: function () { return import(/* webpackChunkName: "CGU" */ '../views/legal/cgu.vue') }      
      },
      {
        path: 'licence',
        name: 'licence',
        component: function () { return import(/* webpackChunkName: "licence" */ '../views/legal/licence.vue') }      
      },
      {
        path: 'librairies-logiciels-tiers',
        name: 'librairies-logiciels-tiers',
        component: function () { return import(/* webpackChunkName: "librairies-logiciels-tiers" */ '../views/legal/librairies-logiciels-tiers.vue') }      
      },
      {
        path: 'vos-donnees',
        name: 'vos-donnees',
        component: function () { return import(/* webpackChunkName: "vos-donnees" */ '../views/legal/vos-donnees.vue') }      
      },
      // PLANIFICATION
        {
          path: 'planification/:onglet',
          name: 'planification',
          props: true,
          component: function () { return import(/* webpackChunkName: "planification" */ '../views/planification/planification.vue') }      
        },
      // ROUTE 404
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: function () { return import(/* webpackChunkName: "erreur-404" */ '../views/erreur/erreur404.vue') }    
      }
    ],
  },
  
] 

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
